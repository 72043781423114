import React from "react";

// Apollo
import { useMutation, useApolloClient } from "@apollo/client";
import { DELETE_PAGE_BLOCK, PAGE_VERSION, CLONE_PAGE_VERSION } from "../../@global/queries";

// Redux
import { connect, useDispatch } from "react-redux";
import { SET_PAGE_VERSION_ID } from "../../redux/Types";

// MUI
import { useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import Fade from "@material-ui/core/Fade";

import { MainContainer } from "./styles";

// Icons
import { plus_icon } from "../../@global/icons";

// Utils
import { updateCacheOnCloneVersion } from "../../@utils/apollo";

import logger from "../../@global/logger";

// Components
import ContentBlock from "../ContentBlock";
import EditorDrawer from "../../@common/EditorDrawer";
import WarningDialogue from "../../@common/WarningDialogue";
import GlobalSpinner from "../../@common/GlobalSpinner";

// Default
const PageContent = (props) => {
  const {
    page_id,
    page_uri,
    is_live,
    version_data,
    dynamic_path_data,
    page_block_selector_state,
    addNewBlockCb,
    is_edit_mode,
    header_height,
    location_state,
  } = props;

  const page_version_blocks_data = version_data.page_version_blockItems || [];

  const block_items = page_version_blocks_data
    .map((content_block) => ({ ...content_block }))
    .sort((a, b) => a.weight - b.weight);

  // Hooks
  const theme = useTheme();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const BlockSelectorComp = React.useRef();
  const [show_warning, setShowWarning] = React.useState(false);
  const [show_spinner, setShowSpinner] = React.useState(false);
  const [is_locked, setLocked] = React.useState(is_live);

  const [deletePageBlock] = useMutation(DELETE_PAGE_BLOCK, {
    update: (cache, { data: { deletePageBlock } }) => {
      cache.evict({ id: cache.identify(deletePageBlock) });
      cache.gc();
    },
    refetchQueries: [
      {
        query: PAGE_VERSION,
        variables: {
          page_id,
          id: version_data.id,
        },
      },
    ],
    onError: (error) => {
      logger.error(error);
    },
  });

  const [cloneVersion] = useMutation(CLONE_PAGE_VERSION, {
    update: updateCacheOnCloneVersion,
    onCompleted: ({ cloneVersion }) => {
      setShowSpinner(false);

      dispatch({
        type: SET_PAGE_VERSION_ID,
        payload: {
          page_id,
          ver_id: cloneVersion.id,
        },
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });

  const version_cache_id = client.cache.identify(version_data);

  // Dynamic imports

  BlockSelectorComp.current =
    BlockSelectorComp.current || React.lazy(() => import("./BlockSelector"));

  // Rendering
  return (
    <MainContainer theme={theme} header_height={header_height}>
      {React.useMemo(() => {
        return (
          <>
            {is_edit_mode && block_items.length === 0 && (
              <Fade in={true} timeout={150}>
                <div className="new-page-message">
                  <Typography color="textPrimary" gutterBottom>
                    This is your new page.
                    <br />
                    Start building by adding content blocks.
                  </Typography>
                  <br />
                  <Button
                    className="editing-ui"
                    variant="contained"
                    color="primary"
                    onMouseDown={(e) => {
                      addNewBlockCb({ show: true, weight: 0 });
                    }}
                  >
                    <SvgIcon>{plus_icon}</SvgIcon>Add a block
                  </Button>
                </div>
              </Fade>
            )}
          </>
        );
      }, [is_edit_mode, block_items.length, addNewBlockCb])}

      {React.useMemo(() => {
        return (
          <>
            {block_items.map((block_data) => {
              return (
                <React.Fragment key={block_data.id}>
                  <ContentBlock
                    block_data={block_data}
                    is_locked={is_locked}
                    page_uri={page_uri}
                    liveEditAttemptCb={() => setShowWarning(true)}
                    dynamic_path_data={dynamic_path_data}
                    location_state={location_state}
                    version_id={version_data.id}
                    version_cache_id={version_cache_id}
                    addNewBlockCb={(weight) => {
                      addNewBlockCb({ show: true, weight });
                    }}
                    deleteBlockCb={(id) => {
                      deletePageBlock({
                        variables: {
                          id,
                        },
                      });
                    }}
                  />
                </React.Fragment>
              );
            })}
          </>
        );
      }, [
        block_items,
        is_locked,
        page_uri,
        dynamic_path_data,
        location_state,
        deletePageBlock,
        addNewBlockCb,
        version_cache_id,
        version_data.id,
      ])}

      {React.useMemo(() => {
        const onDrawerClose = (e) => {
          addNewBlockCb({ show: false, weight: 0 });
        };

        return (
          <>
            {is_edit_mode && (
              <EditorDrawer open={page_block_selector_state.show} onClose={onDrawerClose}>
                <React.Suspense fallback={null}>
                  <BlockSelectorComp.current
                    closeCb={onDrawerClose}
                    version_id={version_data.id}
                    version_cache_id={version_cache_id}
                    weight={page_block_selector_state.weight}
                  />
                </React.Suspense>
              </EditorDrawer>
            )}
          </>
        );
      }, [
        version_data.id,
        is_edit_mode,
        page_block_selector_state,
        version_cache_id,
        addNewBlockCb,
      ])}

      {React.useMemo(() => {
        return (
          <>
            {is_edit_mode && (
              <WarningDialogue
                show_warning={show_warning}
                setShowWarning={setShowWarning}
                primaryActionCb={() => {
                  setShowSpinner(true);

                  cloneVersion({
                    variables: {
                      id: version_data.id,
                    },
                  });
                }}
                secondaryActionCb={() => setLocked(false)}
                primary_btn_txt="Clone and Edit"
                secondary_btn_txt="Continue editing"
              >
                <Typography className="editing-ui" variant="h5" gutterBottom>
                  This page is currently live!
                </Typography>
                <Typography className="editing-ui" paragraph>
                  Would you like to clone it to make changes, and then publish the updated verison?
                </Typography>
              </WarningDialogue>
            )}
          </>
        );
      }, [is_edit_mode, show_warning, cloneVersion, version_data.id])}

      <GlobalSpinner show={show_spinner} />
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    is_edit_mode: state.Editor.is_edit_mode,
    header_height: state.Layout.header_height,
  };
};

export default connect(mapStateToProps)(PageContent);

PageContent.whyDidYouRender = {
  logOnDifferentValues: false,
};
