import React from "react";

// Apollo GraphGL
import { useQuery } from "@apollo/client";
import { PAGES } from "../../@global/queries";

// Redux
import { connect, useDispatch } from "react-redux";
import { SET_IS_EDITOR, SET_SITE_EDIT_MODE } from "../../redux/Types";

// Router
import { Router, Redirect } from "@reach/router";

// MUI
import Typography from "@material-ui/core/Typography";

// Utils
import { onRequestError } from "../../@utils/apollo";
import { getSiteId, getURLvars } from "../../@utils/general";

// Components
import PageRoute from "../PageRoute";

const NotFound = ({ is_admin, is_edit_mode }) => {
  // Hooks
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({ type: SET_IS_EDITOR, payload: false });

    if (is_edit_mode && !is_admin) dispatch({ type: SET_SITE_EDIT_MODE, payload: false });
  }, [dispatch, is_admin, is_edit_mode]);

  return (
    <div className="not-found">
      <Typography variant="h1">404</Typography>
      <Typography variant="body1">{`Oops something went wrong :(`}</Typography>
      <Typography variant="body1">The page you requested doesn't seem to exist</Typography>
    </div>
  );
};

const BlankPage = (props) => {
  return <div></div>;
};

const PageRouter = ({
  home_uri = "/",
  page_block_selector_state,
  setPageBlockSelectorState,
  setCurrentPageData,
  current_page_id,
  is_edit_mode,
  is_admin,
}) => {
  const share_token = getURLvars(window.location.search).share_token;

  const page_id = React.useRef();

  const pages = useQuery(PAGES, {
    onError: onRequestError,
    variables: {
      site_id: getSiteId(),
      share_token,
    },
  });

  const content_pages_data = (pages.data || {}).pageItems || [];

  React.useEffect(() => {
    if (current_page_id !== page_id.current) {
      page_id.current = current_page_id;

      // current_page_id will only be determined after the current page route is rendered.
      // Hence expect this comp to be re-rendered twice on intial load
      const cur_page_data = content_pages_data.find((page) => page.id === current_page_id) || {};

      setCurrentPageData(cur_page_data);
    }
  }, [current_page_id, content_pages_data, setCurrentPageData]);

  return (
    <Router className={`content-container ${is_edit_mode && "edit-mode"}`}>
      <Redirect from="/" to={home_uri} noThrow />
      {content_pages_data.map((page_data, index) => (
        <PageRoute
          key={`static${index}`}
          path={page_data.uri}
          page_data={page_data}
          page_block_selector_state={page_block_selector_state}
          addNewBlockCb={setPageBlockSelectorState}
        />
      ))}

      {content_pages_data.map((page_data, index) => (
        <PageRoute
          key={`dynamic${index}`}
          path={`${page_data.uri}/:dynamic_path_data`}
          page_data={page_data}
          page_block_selector_state={page_block_selector_state}
          addNewBlockCb={setPageBlockSelectorState}
        />
      ))}

      {Boolean(pages.data) ? (
        <NotFound default is_admin={is_admin} is_edit_mode={is_edit_mode} />
      ) : (
        <BlankPage default />
      )}
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    current_page_id: state.Nav.current_page_id,
    is_edit_mode: state.Editor.is_edit_mode,
    is_admin: state.UI.is_admin,
  };
};

export default connect(mapStateToProps)(PageRouter);
