// Site
export const SET_CURRENT_PAGE_ID = "SET_CURRENT_PAGE_ID";
export const EVICT_PAGE_VERSION = "EVICT_PAGE_VERSION";

// UI
export const SET_HEADER_HEIGHT = "SET_HEADER_HEIGHT";
export const TOGGLE_DATA_SAVING_UI = "TOGGLE_DATA_SAVING_UI";
export const SET_SHOW_MENUS_DRAWER = "SET_SHOW_MENUS_DRAWER";
export const SET_REQUESTED_MENU_ID = "SET_REQUESTED_MENU_ID";
export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const SET_IS_EDITOR = "SET_IS_EDITOR";
export const REQUEST_CALLOUT = "REQUEST_CALLOUT";
export const SET_CALLOUT = "SET_CALLOUT";

// User
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const SET_CALLS_APPLICATIONS_STATE = "SET_CALLS_APPLICATIONS_STATE";
export const SET_SHOPPING_CALLS_APPLICATIONS_STATE = "SET_SHOPPING_CALLS_APPLICATIONS_STATE";

// Editor
export const SET_SITE_EDIT_MODE = "SET_SITE_EDIT_MODE";
export const SET_PAGE_VERSION_ID = "SET_PAGE_VERSION_ID";
