import styled from "styled-components";

export const MainContainer = styled.main`
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .col-1{
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-2{
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-3{
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4{
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-5{
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-6{
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7{
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-8{
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-9{
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10{
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-11{
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-12{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .offline-disclaimer {
    background-color: ${(p) => p.theme.palette.aria.warning};
    color: white;
    text-align: center;
    padding: 5px;
  }

  .checkbox-filter-group{
    width: 60%;
    border: #b6abab 1px dotted;
    margin: 35px 20%;
    padding: 25px 50px;
  }
  .checkbox-filter-group-mobile{
    width: 80%;
    border: #b6abab 1px dotted;
    margin: auto;
    margin-top: 25px
    padding: 25px 50px;
  }
  .form-check-label{
    color: #0137ad;
  }


`;
