import styled from "styled-components";

// Utils
import { admin_bar_height } from "../../@global/const";

export const MainContainer = styled.div`
  display: block;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .admin-bar {
    width: 100%;
    height: ${admin_bar_height}px;
    position: fixed;
    z-index: ${(p) => p.theme.zIndex.admin_bar};
    top: -${admin_bar_height}px;
    /* filter: drop-shadow(1px 1px 3px rgba(48, 49, 51, 0.4)); */

    transition: top ${(p) => p.theme.transitions.duration.standard}ms
      ${(p) => p.theme.transitions.easing.easeOut};

    /* TODO: temp measure before we decide how to handle admin mode on screens >960px */
    min-width: 960px;

    &.edit-mode {
      top: 0px;
    }
  }

  .content-container {
    padding-top: ${(p) => p.header_height}px;

    transition: padding-top ${(p) => p.theme.transitions.duration.standard}ms
      ${(p) => p.theme.transitions.easing.easeOut};

    &.edit-mode {
      padding-top: ${(p) => p.header_height + admin_bar_height}px;
    }
  }

  .not-found {
    max-width: 400px;
    text-align: center;
    padding-top: ${(p) => p.header_height}px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const LayoutHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: ${(p) => p.theme.zIndex.header};

  transition: top ${(p) => p.theme.transitions.duration.standard}ms
    ${(p) => p.theme.transitions.easing.easeOut};

  &.edit-mode {
    top: ${admin_bar_height}px;
  }
`;

export const LayoutFooter = styled.footer`
  width: 100%;
`;
