/*
 *   ******************
 *   Logger
 *   ******************
 */

// Logging solution uses winston library
// https://github.com/winstonjs/winston

// Define variables locally for use
// LOG_LEVEL determines the maximum level of log that will be transported ie. error will log everything with level emerg, alert, crit and error
// LOG_ENV has three options: production will only log to file, combo will log to file and console and dev will log to console only

// To use, import logger and use the format 'logger.level("message" + variable + "message"); where level might be info, warn, error etc. For objects, these can be passed as follows: logger.error("The following object isn't working: ", objectName);

const winston = require("winston");

const LOG_LEVEL = (window._env_ || {}).REACT_APP_LOG_LEVEL || "error";
const LOG_ENV = (window._env_ || {}).REACT_APP_LOG_ENV || "production";

const consoleOptions = {
  level: LOG_LEVEL,
  handleExceptions: true,
  //defaultMeta: { site: 'site-name' },
  format: winston.format.combine(
    winston.format.colorize(),
    winston.format.prettyPrint(JSON.stringify),
    winston.format.splat(),
    winston.format.simple()
  ),
};

// This will be used to transport logs to AWS
// const httpOptions = {
//   // - Write all logs at level given and below (for production, should be error level)
//   level: LOG_LEVEL,
//   handleExceptions: true,
//   timestamps: true,
//   //defaultMeta: { site: 'site-name' },
//   format: winston.format.combine(
//     winston.format.timestamp(),
//     winston.format.splat(),
//     winston.format.json()
//   ),
//   host: '',
//   port: '',
//   path: '',
//   ssl: true
// };

const logger = (function () {
  if (LOG_ENV === "production") {
    return winston.createLogger({
      transports: [
        new winston.transports.Console(consoleOptions),
        // new (winston.transports.http)(httpOptions),
      ],
    });
  }
  // Non-production environments
  return winston.createLogger({
    transports: [new winston.transports.Console(consoleOptions)],
  });
})();

export default logger;
