import styled from "styled-components";

import Modal from "@material-ui/core/Modal";

export const MainContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    background-color: ${(p) => p.theme.palette.aria.background_primary};
    max-width: 430px;
    width: 90%;
    outline: 0px;
    border-radius: 6px;
    padding: 25px;
  }

  .modal-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiTypography-root {
      text-align: center;
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;

    .MuiButton-root {
      margin-left: 10px;

      height: 32px;
    }

    .MuiButton-label {
      height: 21px;
    }
  }
`;
