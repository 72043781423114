import React from "react";

// Redux
import { connect, useDispatch } from "react-redux";
import { SET_IS_ADMIN } from "../../redux/Types";

// Apollo GraphGL
import { useLazyQuery, useMutation } from "@apollo/client";

import { SITE_DATA, JOIN_SITE } from "../../@global/queries";

// CSS & MUI
import GlobalStyles from "../../@global/styles";
import { ThemeProvider as ScThemeProvider } from "styled-components";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

// Utils
import WebFont from "webfontloader";
import { default_theme_obj } from "../../@global/const";
import { getSiteId } from "../../@utils/general";
import logger from "../../@global/logger";
import { useKeycloak } from "@react-keycloak/web";

// Components
import SiteLayout from "../SiteLayout";

const Site = ({ bearer_token }) => {
  // Hooks
  const [keycloak] = useKeycloak();
  const fonts = React.useRef("");
  const joined_site = React.useRef();
  const authenticated = React.useRef();
  const dispatch = useDispatch();

  const site_id = getSiteId();

  const loadFonts = (theme) => {
    // TODO: Include all the fonts from each style tag
    // TODO: Switch to proper Google Fonts API for font management
    const font_to_load = theme.typography.fontFamily.split(",")[0].replace(/"/g, "").trim();

    if (fonts.current !== font_to_load) {
      fonts.current = font_to_load;

      const fonts_config = {
        classes: false,
        timeout: 3000,
        loading: () => {
          logger.debug(`${font_to_load} font is loading ...`);
        },
        active: () => {
          logger.debug(`${font_to_load} font is loaded`);
        },
        inactive: () => {
          logger.debug(`${font_to_load} Font is inactive`);
        },
        google: {
          families: [`${font_to_load}:300,400,500,700`],
        },
      };

      logger.debug(`Load ${font_to_load} font`);

      WebFont.load(fonts_config);
    }
  };

  const [loadSite, { data }] = useLazyQuery(SITE_DATA, {
    onCompleted: (data) => {
      const site_item = (data || {}).siteItem || {};

      dispatch({ type: SET_IS_ADMIN, payload: site_item.is_admin });
    },
    onError: (error) => {
      logger.error(error);
    },
    fetchPolicy: "cache-and-network",
  });

  const [joinSite] = useMutation(JOIN_SITE, {
    onError: (error) => {
      logger.error(error);
    },
  });

  React.useEffect(() => {
    if (keycloak.authenticated !== authenticated.current) {
      authenticated.current = keycloak.authenticated;

      loadSite({
        variables: {
          id: site_id,
        },
      });
    }

    if (!joined_site.current && Boolean(bearer_token)) {
      joined_site.current = true;

      joinSite({ variables: { site_id } });
    }
  }, [keycloak.authenticated, joinSite, loadSite, site_id, bearer_token]);

  //
  const site_data = (data || {}).siteItem || {};
  const theme_obj =
    Object.entries(site_data.theme || {}).length === 0 ? default_theme_obj : site_data.theme;
  const theme = responsiveFontSizes(createMuiTheme(theme_obj));

  loadFonts(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <ScThemeProvider theme={theme}>
        <GlobalStyles theme={theme} />
        {React.useMemo(
          () => (
            <SiteLayout home_uri={site_data.home_uri} />
          ),
          [site_data.home_uri]
        )}
      </ScThemeProvider>
    </MuiThemeProvider>
  );
};

export default connect((state) => ({ bearer_token: state.User.token }))(Site);

Site.whyDidYouRender = {
  logOnDifferentValues: false,
};
