import React from "react";
import PropTypes from "prop-types";

// Mui
import { MainContainer } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

/**
 * A standartised MUI Modal component wrapper for displaying warning messages withing the Site Builder's framework
 *
 * @module WarningDialogue
 *
 * @param {Boolean} props.show_warning - A flag to tell the component where to render itself or not.
 * @param {Function} props.setShowWarning - A callback fucntion, controlling where to show or hide the dialogue. Accept boolean flag as a parameter
 * @param {Function} [props.primaryActionCb] - A callback function, triggered upon primary button click. Doesn't expect any params
 * @param {Function} [props.secondaryActionCb] - A callback function, triggered upon secondary button click. Doesn't expect any params
 * @param {String} [props.primary_btn_txt] - Primary button text
 * @param {String} [props.secondary_btn_txt] - Secondary button text
 * @param {HTMLnodes} props.children - Anything that can be rendered: numbers, strings, elements or an array
 */

const WarningDialogue = (props) => {
  const {
    show_warning,
    setShowWarning,
    primaryActionCb,
    secondaryActionCb,
    primary_btn_txt,
    secondary_btn_txt,
    children,
  } = props;

  // Hooks
  const theme = useTheme();

  return (
    <MainContainer
      theme={theme}
      open={show_warning}
      onClose={() => setShowWarning(false)}
      closeAfterTransition
      disableRestoreFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={show_warning}>
        <div className="content">
          <div className="modal-message">{children}</div>
          <div className="modal-buttons">
            {secondaryActionCb && (
              <Button
                className="editing-ui"
                color="secondary"
                type="submit"
                onClick={() => {
                  setShowWarning(false);

                  if (secondaryActionCb) secondaryActionCb();
                }}
              >
                {secondary_btn_txt || "Cancel"}
              </Button>
            )}

            <Button
              className="editing-ui"
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                setShowWarning(false);

                if (primaryActionCb) primaryActionCb();
              }}
            >
              {primary_btn_txt || "Proceed"}
            </Button>
          </div>
        </div>
      </Fade>
    </MainContainer>
  );
};

WarningDialogue.propTypes = {
  show_warning: PropTypes.bool.isRequired,
  setShowWarning: PropTypes.func.isRequired,
  primaryActionCb: PropTypes.func,
  secondaryActionCb: PropTypes.func,
  primary_btn_txt: PropTypes.string,
  secondary_btn_txt: PropTypes.string,
};

export default WarningDialogue;
