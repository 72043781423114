import styled from "styled-components";

// MUI
import Drawer from "@material-ui/core/Drawer";

// Utils
import { admin_bar_height } from "../../@global/const";

export const MainContainer = styled(Drawer)`
  .MuiBackdrop-root,
  .MuiDrawer-paper {
    top: ${admin_bar_height}px;

    min-width: 250px;
  }

  .null-drawer-content {
    padding: 20px;
    min-width: 250px;
  }
`;
