import React from "react";

// Apollo
import { useMutation } from "@apollo/client";
import { UPDATE_PAGE_BLOCK, MOVE_PAGE_BLOCK, ADD_PAGE_BLOCK } from "../../@global/queries";

// Redux
import { connect } from "react-redux";

// MUI
import { MainContainer } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

// Components
import EditorDrawer from "../../@common/EditorDrawer";

// Utils
import {
  DRAWER_CONTENT_SETTINGS,
  DRAWER_CONTENT_CONTENT,
  DRAWER_CONTENT_STYLE,
  INSTRUMENTS_DUPLICATE,
  INSTRUMENTS_DELETE,
  INSTRUMENTS_UP,
  INSTRUMENTS_DOWN,
} from "../../@global/const";
import { getPointBoxCollides, getAnchorHash, copyTextToClipboard } from "../../@utils/general";
import { updateCacheOnCreatePageBlock } from "../../@utils/apollo";
import logger from "../../@global/logger";

/**
 * A top level building block of the Site Builder framework. It is responsible for
 * dynamically rendering pages's basic structure elements (i.e. Header and Footer)
 *
 * @module ContentBlock
 *
 * @param {Object} props.block_data - Layout block's data
 * @param {Boolean} props.is_locked - A flag preventing block edits
 * @param {Function} props.liveEditAttemptCb - A callback function, fired when a block's, added to a "published" page version, content is being attempted to be edited
 * @param {Array} props.dynamic_path_data - A dynamic component of the currently loaded page's url
 */
const ContentBlock = (props) => {
  const {
    block_data,
    is_locked,
    liveEditAttemptCb,
    dynamic_path_data,
    location_state,
    addNewBlockCb,
    deleteBlockCb,
    is_edit_mode,
    header_height,
    page_uri,
    version_id,
    version_cache_id,
  } = props;

  const url_hash = getAnchorHash(window.location.hash);
  const url_search = window.location.search;

  // Hooks
  const theme = useTheme();
  const has_initialized = React.useRef();
  const main_container_ref = React.useRef();
  const DynamicPageBlockComp = React.useRef({});
  const DynamicSettingsComp = React.useRef({});
  const DynamicContentComp = React.useRef({});
  const StyleSelectorComp = React.useRef();
  const InstrumentsPanel = React.useRef();
  const AddBlockButton = React.useRef();
  const [instr_visible, setInstrVisible] = React.useState(false);
  const [show_editor_drawer, setShowEditorDrawer] = React.useState(false);
  const [drawer_content_type, setDrawerContentType] = React.useState();

  const [updateBlock] = useMutation(UPDATE_PAGE_BLOCK, {
    onError: (error) => {
      logger.error(error);
    },
  });

  const [moveBlock, move_result] = useMutation(MOVE_PAGE_BLOCK, {
    onError: (error) => {
      logger.error(error);
    },
  });

  const [createBlock] = useMutation(ADD_PAGE_BLOCK, {
    update: updateCacheOnCreatePageBlock(version_cache_id),
    onError: (error) => {
      logger.error(error);
    },
  });

  React.useLayoutEffect(() => {
    if (has_initialized.current && main_container_ref.current) {
      if (url_hash.slice(1) === block_data.reference) {
        window.scroll({
          top: main_container_ref.current.offsetTop - header_height,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [block_data.reference, url_hash, location_state, header_height]);

  const block_component = block_data.block_component;
  const content_data = (block_data.page_version_block_contentItems || [])[0] || {};

  // Dynamic imports

  StyleSelectorComp.current =
    StyleSelectorComp.current || React.lazy(() => import("./StyleSelector"));

  InstrumentsPanel.current = InstrumentsPanel.current || React.lazy(() => import("./Instruments"));

  AddBlockButton.current =
    AddBlockButton.current || React.lazy(() => import("../../@common/AddBlockButton"));

  DynamicPageBlockComp.current[block_component] =
    DynamicPageBlockComp.current[block_component] ||
    React.lazy(() =>
      import(`../../page.blocks/${block_component}`).catch((error) => {
        logger.error(error);
        return import("./NullBlockComp");
      })
    );

  DynamicSettingsComp.current[block_component] =
    DynamicSettingsComp.current[block_component] ||
    React.lazy(() =>
      import(`../../page.blocks/${block_component}/Settings`).catch((error) => {
        logger.error(error);
        return import("./NullDrawerContent");
      })
    );

  DynamicContentComp.current[block_component] =
    DynamicContentComp.current[block_component] ||
    React.lazy(() =>
      import(`../../page.blocks/${block_component}/Content`).catch((error) => {
        logger.error(error);
        return import("./NullDrawerContent");
      })
    );

  // Handlers
  const onDrawerClose = (e) => {
    e.persist();

    setShowEditorDrawer(false);

    if (main_container_ref.current) {
      const point = { x: e.clientX, y: e.clientY };
      const my_box = main_container_ref.current.getBoundingClientRect();

      if (!getPointBoxCollides(point, my_box)) {
        setInstrVisible(false);
      }
    }
  };

  return (
    <MainContainer
      id={block_data.reference}
      theme={theme}
      ref={main_container_ref}
      onMouseEnter={() => {
        is_edit_mode && setInstrVisible(true);
      }}
      onMouseLeave={() => {
        is_edit_mode && !show_editor_drawer && setInstrVisible(false);
      }}
    >
      {React.useMemo(() => {
        if (!block_component) return null;

        const CurDynamicPageBlockComp = DynamicPageBlockComp.current[block_component];

        return (
          <React.Suspense fallback={null}>
            <CurDynamicPageBlockComp
              id={block_data.id}
              reference={block_data.reference}
              content={content_data.content || {}}
              options={content_data.options || {}}
              dynamic_path_data={dynamic_path_data}
              url_search={url_search}
              url_hash={url_hash}
              page_uri={page_uri}
              allow_edit={!is_locked}
              editCb={() => liveEditAttemptCb()}
              updateCb={(content, options) => {
                updateBlock({
                  variables: {
                    id: block_data.id,
                    reference: block_data.reference,
                    block_component: block_data.block_component,
                    weight: block_data.weight,
                    content: content || content_data.content,
                    options: options || content_data.options,
                  },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateBlock: {
                      __typename: "Page_version_blockType",
                      id: block_data.id,
                      reference: block_data.reference,
                      block_component: block_data.block_component,
                      weight: block_data.weight,
                      page_version_block_contentItems: [
                        {
                          __typename: "Page_version_block_contentType",
                          id: content_data.id,
                          content: content || content_data.content,
                          options: options || content_data.options,
                        },
                      ],
                    },
                  },
                });
              }}
              showSettingsCb={() => {
                if (is_locked) {
                  liveEditAttemptCb();
                } else {
                  setDrawerContentType(DRAWER_CONTENT_SETTINGS);
                  setShowEditorDrawer(true);
                }
              }}
              showContentCb={() => {
                if (is_locked) {
                  liveEditAttemptCb();
                } else {
                  setDrawerContentType(DRAWER_CONTENT_CONTENT);
                  setShowEditorDrawer(true);
                }
              }}
              onInitializedCb={() => {
                if (!has_initialized.current) {
                  has_initialized.current = true;

                  if (block_data.reference && url_hash.slice(1) === block_data.reference) {
                    setTimeout(() => {
                      window.scroll({
                        top: main_container_ref.current.offsetTop - header_height,
                        left: 0,
                        behavior: "smooth",
                      });
                    }, 500);
                  }
                }
              }}
            />
          </React.Suspense>
        );
      }, [
        url_hash,
        is_locked,
        page_uri,
        block_data.id,
        block_data.reference,
        block_data.block_component,
        block_data.weight,
        header_height,
        block_component,
        dynamic_path_data,
        url_search,
        content_data.id,
        content_data.content,
        content_data.options,
        liveEditAttemptCb,
        updateBlock,
      ])}

      {React.useMemo(() => {
        const moveBlockCb = (down) => {
          moveBlock({
            variables: {
              id: block_data.id,
              delta: down ? 1 : -1,
            },
          });
        };

        return (
          <>
            {instr_visible && is_edit_mode && (
              <React.Suspense fallback={null}>
                <InstrumentsPanel.current
                  component={block_component}
                  onLinkCopy={() =>
                    copyTextToClipboard(
                      `${window.location.origin}${window.location.pathname}#${block_data.reference}`
                    )
                  }
                  buttonDownCb={(type) => {
                    if (is_locked) {
                      liveEditAttemptCb();
                    } else {
                      switch (type) {
                        case INSTRUMENTS_DUPLICATE:
                          createBlock({
                            variables: {
                              page_version_id: version_id,
                              weight: block_data.weight + 1,
                              component: block_component,
                              content: content_data.content,
                              options: content_data.options,
                            },
                          });
                          break;
                        case INSTRUMENTS_DELETE:
                          deleteBlockCb(block_data.id);
                          break;
                        case INSTRUMENTS_UP:
                          moveBlockCb(false);
                          break;
                        case INSTRUMENTS_DOWN:
                          moveBlockCb(true);
                          break;
                        default:
                          setDrawerContentType(type);
                          setShowEditorDrawer(true);
                      }
                    }
                  }}
                />
                <AddBlockButton.current
                  onMouseDown={(e) => {
                    if (is_locked) {
                      liveEditAttemptCb();
                    } else {
                      addNewBlockCb(block_data.weight + 1);
                    }
                  }}
                />
              </React.Suspense>
            )}
          </>
        );
      }, [
        instr_visible,
        is_locked,
        is_edit_mode,
        block_data.weight,
        block_data.id,
        block_data.reference,
        addNewBlockCb,
        deleteBlockCb,
        block_component,
        moveBlock,
        liveEditAttemptCb,
        content_data.content,
        content_data.options,
        version_id,
        createBlock,
      ])}

      {React.useMemo(() => {
        const CurDynamicSettingsComp = DynamicSettingsComp.current[block_component];

        const CurDynamicContentComp = DynamicContentComp.current[block_component];

        return (
          <>
            {is_edit_mode && (
              <EditorDrawer open={show_editor_drawer} onClose={onDrawerClose}>
                {drawer_content_type === DRAWER_CONTENT_STYLE && (
                  <React.Suspense fallback={null}>
                    <StyleSelectorComp.current
                      block_id={block_data.id}
                      component={block_component}
                      closeCb={onDrawerClose}
                    />
                  </React.Suspense>
                )}
                {drawer_content_type === DRAWER_CONTENT_SETTINGS && (
                  <React.Suspense fallback={null}>
                    <CurDynamicSettingsComp
                      content={content_data.content || {}}
                      options={content_data.options || {}}
                      closeCb={onDrawerClose}
                      updateCb={(options) => {
                        updateBlock({
                          variables: {
                            id: block_data.id,
                            reference: block_data.reference,
                            block_component: block_data.block_component,
                            weight: block_data.weight,
                            options: options || content_data.options,
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            updateBlock: {
                              __typename: "Page_version_blockType",
                              id: block_data.id,
                              reference: block_data.reference,
                              block_component: block_data.block_component,
                              weight: block_data.weight,
                              page_version_block_contentItems: [
                                {
                                  __typename: "Page_version_block_contentType",
                                  id: content_data.id,
                                  content: content_data.content,
                                  options: options || content_data.options,
                                },
                              ],
                            },
                          },
                        });
                      }}
                    />
                  </React.Suspense>
                )}
                {drawer_content_type === DRAWER_CONTENT_CONTENT && (
                  <React.Suspense fallback={null}>
                    <CurDynamicContentComp
                      content={content_data.content || {}}
                      options={content_data.options || {}}
                      closeCb={onDrawerClose}
                      updateCb={(content) => {
                        updateBlock({
                          variables: {
                            id: block_data.id,
                            reference: block_data.reference,
                            block_component: block_data.block_component,
                            weight: block_data.weight,
                            content: content || content_data.content,
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            updateBlock: {
                              __typename: "Page_version_blockType",
                              id: block_data.id,
                              reference: block_data.reference,
                              block_component: block_data.block_component,
                              weight: block_data.weight,
                              page_version_block_contentItems: [
                                {
                                  __typename: "Page_version_block_contentType",
                                  id: content_data.id,
                                  content: content || content_data.content,
                                },
                              ],
                            },
                          },
                        });
                      }}
                    />
                  </React.Suspense>
                )}
              </EditorDrawer>
            )}
          </>
        );
      }, [
        is_edit_mode,
        block_component,
        show_editor_drawer,
        drawer_content_type,
        block_data.id,
        block_data.reference,
        block_data.block_component,
        block_data.weight,
        content_data.id,
        content_data.content,
        content_data.options,
        updateBlock,
      ])}

      {move_result.loading && (
        <Fade in={true}>
          <div className="loading-screen">
            <div className="progress-spinner">
              <CircularProgress className="editing-ui" />
            </div>
          </div>
        </Fade>
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    is_edit_mode: state.Editor.is_edit_mode,
    header_height: state.Layout.header_height,
  };
};

export default connect(mapStateToProps)(ContentBlock);
