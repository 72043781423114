import React from "react";

// Apollo
import { useMutation } from "@apollo/client";
import { PUBLISH_PAGE_VERSION } from "../../../@global/queries";

// Redux
import { connect } from "react-redux";

// MUI
import { MainContainer } from "./styles";
import { useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Utils
import logger from "../../../@global/logger";

const PageStatusBar = (props) => {
  const {
    page_name,
    page_visibility,
    //pageCb,
    version_nr,
    version_active,
    version_id,
    is_edit_mode,
  } = props;
  // Hooks
  const theme = useTheme();

  const [publishVersion] = useMutation(PUBLISH_PAGE_VERSION, {
    onError: (error) => {
      logger.error(error);
    },
  });

  const getPageStatusColor = () => {
    switch (page_visibility) {
      case "offline":
        return theme.palette.aria.error;
      case "private":
        return theme.palette.aria.warning;
      case "online":
        return theme.palette.aria.success;
      default:
        return "inherit";
    }
  };

  const getVersionStatusColor = () => {
    switch (Boolean(version_active)) {
      case true:
        return theme.palette.aria.success;
      case false:
        return theme.palette.aria.warning;
      default:
        return "inherit";
    }
  };

  // Render
  return (
    <MainContainer theme={theme}>
      <div className="page-status">
        <Typography>
          {page_name} is{" "}
          <span style={{ color: getPageStatusColor() }}>
            {page_visibility
              .charAt(0)
              .toUpperCase()
              .concat(String(page_visibility).slice(1, String(page_visibility).length))}
          </span>
        </Typography>
        {/* {is_edit_mode && (
          <Button variant="contained" onMouseDown={pageCb}>
            Change
          </Button>
        )} */}
      </div>
      <div className="version-status">
        <Typography>
          Version {String(version_nr).padStart(3, "0")}{" "}
          <span style={{ color: getVersionStatusColor() }}>
            {version_active ? "Published" : "Draft"}
          </span>
        </Typography>
        {is_edit_mode && !version_active && (
          <Button
            variant="contained"
            onMouseDown={(e) => {
              publishVersion({
                variables: {
                  id: version_id,
                },
              });
            }}
          >
            Publish
          </Button>
        )}
      </div>
    </MainContainer>
  );
};

export default connect((state) => ({
  is_edit_mode: state.Editor.is_edit_mode,
}))(PageStatusBar);
