import styled from "styled-components";

export const MainContainer = styled.section`
  position: relative;

  .loading-screen {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .progress-spinner {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);
  }
`;
