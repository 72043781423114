import styled from "styled-components";

export const TagFilter = styled.div`
  background-color: ${(p) => p.bg_color};

  .groupOfTag-title{
    font-weight: bold;
    font-size: 16px;
  }
`;

export const GroupOfTags = styled.div`
  background-color: ${(p) => p.bg_color};
`;

export const Tags = styled.div`
  background-color: ${(p) => p.bg_color};
`;
