import React from "react";

// Redux
import { connect } from "react-redux";

import Store from "../redux/Store";
import { setUser, clearUser } from "../redux/actions/UserActions";

// Apollo GraphGL
import {
  from,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client";

// Keycloak
import Keycloak from "keycloak-js";
import { KeycloakProvider } from "@react-keycloak/web";

// MUI
import { StylesProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// Utils
import { version } from "../../package.json";
import logger from "../@global/logger";
import { getCacheTypePolicies } from "../@utils/apollo";

// Components
import Site from "./Site";

/*
 *   ******************
 *   App Initialization
 *   ******************
 */

// Keycloak Setup
const keycloak = new Keycloak({
  realm: window._env_.REACT_APP_KEYCLOAK_REALM,
  clientId: window._env_.REACT_APP_KEYCLOAK_CLIENT_ID,
  url: `${window._env_.REACT_APP_KEYCLOAK_BASE_URL}/auth/`,
});

// Apollo Setup
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      ...headers,
      Authorization: Store.getState().User.token ? `Bearer ${Store.getState().User.token}` : "",
      Version: version,
    },
  }));
  return forward(operation);
});

const link = from([
  authLink,
  new HttpLink({
    uri: window._env_.REACT_APP_GRAPHQL,
    credentials: "same-origin",
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(getCacheTypePolicies()),
  link,
});

/*
 *   ******************
 *   Root App Component
 *   ******************
 */

const App = (props) => {
  const { setUser, clearUser } = props;

  // Handlers
  const onKeycloakEvent = (event, error) => {
    error
      ? logger.error(`onKeycloakEvent: ${event}, ${error}`)
      : logger.debug(`onKeycloakEvent: ${event}`);
    logger.debug("User is authenticated: " + keycloak.authenticated);

    if (event === "onAuthLogout") {
      clearUser();
    }
  };

  const onKeycloakTokens = (tokens) => {
    const tokens_ok = Boolean(tokens.idToken);

    logger.debug("onKeycloakTokens. ID token OK:" + tokens_ok);

    if (tokens_ok) {
      setUser(tokens.token, tokens.idToken);
    } else {
      clearUser();
    }
  };

  // Render
  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={{
        onLoad: "check-sso",
        checkLoginIframe: false,
        //silentCheckSsoRedirectUri:
        //  window.location.origin + "/silent-check-sso.html",
      }}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <ApolloProvider client={client}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <Site />
        </StylesProvider>
      </ApolloProvider>
    </KeycloakProvider>
  );
};

export default connect(null, { setUser, clearUser })(App);

App.whyDidYouRender = {
  logOnDifferentValues: false,
};
