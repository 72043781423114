import React from "react";

import { MainContainer } from "./styles";

/**
 * A small utility component to help with left hand side MUI drawer styling and layout (like max width, padding and etc.)
 *
 * @module EditorDrawer
 *
 * @param {Object} props - All the props are benig passed down to the MUI Drawer component @see {@link https://material-ui.com/components/drawers/#drawer}
 */

const EditorDrawer = (props) => {
  return <MainContainer {...props}>{props.children}</MainContainer>;
};

export default EditorDrawer;
