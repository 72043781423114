import React from "react";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import { SET_CURRENT_PAGE_ID, SET_IS_EDITOR, SET_SITE_EDIT_MODE } from "../../redux/Types";

// MUI
import { useTheme } from "@material-ui/core/styles";
import { MainContainer } from "./styles";

// Apollo GraphGL
import { useQuery} from "@apollo/client";
import { PAGE_VERSION } from "../../@global/queries";

// Utils
import { getPageVerVars, getURLvars} from "../../@utils/general";
import logger from "../../@global/logger";

// Components
import PageContent from "../PageContent";
import FilterBlock  from "../../@common/FilterBlock";


const PageRoute = (props) => {
  const {
    path,
    location,
    dynamic_path_data,
    page_data,
    page_block_selector_state,
    addNewBlockCb,
    is_edit_mode,
    page_ver_id_lookup,
    className,
  } = props;

  const ver_vars = getPageVerVars(page_ver_id_lookup, page_data.id);
  const url_vars = getURLvars(location.search);

  // Update index.html meta data and title
  const title_node = document.querySelector("title");
  const title_meta_node = document.querySelector("meta[property='og:title']");
  const desc_meta_node = document.querySelector("meta[property='og:description']");
  const image_meta_node = document.querySelector("meta[property='og:image']");

  if (page_data.title) {
    title_node.innerText = page_data.title;
    title_meta_node.setAttribute("content", page_data.title);
  }

  if (page_data.description) desc_meta_node.setAttribute("content", page_data.description);

  if (page_data.metaimage) image_meta_node.setAttribute("content", page_data.metaimage);

  // Hooks
  const dispatch = useDispatch();
  const current_uri = useSelector((state) => state.Nav.current_uri);
  const theme = useTheme();
   const [page_blocks_data, setPage_version_data] = React.useState({});  // State variables - intial values for a page content. Note that the "page_version_blockItems" is all blocks' contents in a page

  React.useEffect(() => {
    dispatch({ type: SET_IS_EDITOR, payload: page_data.is_editor });

    if (is_edit_mode && !page_data.is_editor) {
      dispatch({ type: SET_SITE_EDIT_MODE, payload: false });
    }

    // Tracking the currently requested page URI via Redux Reducer update
    // is necessary, as various app components outside of the Reach Router
    // need to know about the change (think Admin Bar), and the URI change
    // may be requested via browser's address bar manipulation

    const new_uri = path.replace(`/:dynamic_path_data`, "");

    if (new_uri !== current_uri) {
      dispatch({
        type: SET_CURRENT_PAGE_ID,
        payload: {
          id: page_data.id,
          uri: path.replace(`/:dynamic_path_data`, ""),
        },
      });
    }
  }, [path, current_uri, is_edit_mode, page_data.id, page_data.is_editor, dispatch]);

  
  const { loading, error, data } = useQuery(PAGE_VERSION, {
    onError: (error) => {
      logger.error(error);
    },
    variables: {
      page_id: page_data.id,
      id: ver_vars.id,
      active: ver_vars.active,
      share_token: url_vars.share_token,
    },
  });

   
  
  if (loading){
    return null;
  }
  if (error) {
    logger.error(error);
    return <p>`Error :( ${error}`</p>;
  }
  if (!data){
    return <p>No Page version data was returned by the query</p>;
  }

  // Render
  return ( 
    <>
      
      <MainContainer
        className={className}
        theme={theme}
      >
         <FilterBlock
          is_edit_mode={is_edit_mode}
          data={data}
          page_data={page_data}
          page_blocks_data={page_blocks_data}
          setPage_version_data={setPage_version_data}
        />
        <PageContent
          page_id={page_data.id}
          page_uri={page_data.uri}
          // TODO: Also check for page status, when page visibility control is implemented
          is_locked={page_data.visibility !== "offline" && page_blocks_data.active}
          is_live={page_blocks_data.active}
          version_data={page_blocks_data}
          dynamic_path_data={dynamic_path_data}
          page_block_selector_state={page_block_selector_state}
          addNewBlockCb={addNewBlockCb}
          location_state={location.state}
        />
      </MainContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    is_edit_mode: state.Editor.is_edit_mode,
    page_ver_id_lookup: state.Editor.page_ver_id_lookup,
  };
};

export default connect(mapStateToProps)(PageRoute);

PageRoute.whyDidYouRender = false;
