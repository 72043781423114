import { SET_CURRENT_PAGE_ID } from "../Types";

const initialState = {
  current_page_id: "",
  current_uri: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_PAGE_ID:
      return {
        ...state,
        current_page_id: payload.id,
        current_uri: payload.uri,
      };
    default:
      return state;
  }
}
