import styled from "styled-components";

export const MainContainer = styled.div`
  .new-page-message {
    max-width: 400px;
    text-align: center;
    padding-top: ${(p) => p.header_height}px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);

    .MuiTypography-root {
      font-size: 1.313rem;
      font-weight: 400;
    }

    .MuiButton-label {
      text-transform: none;
      font-weight: 700;
      font-size: 1rem;
    }

    svg {
      margin-right: 10px;
    }

    path {
      fill: white;
    }
  }
`;
