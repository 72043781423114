import React from "react";

// Apollo GraphGL
import { useMutation } from "@apollo/client";

import { UPDATE_SITE_LAYOUT_BLOCK } from "../../@global/queries";

// MUI
import { MainContainer } from "./styles";

// Redux
import { connect, useDispatch } from "react-redux";
import { SET_SHOW_MENUS_DRAWER, SET_REQUESTED_MENU_ID } from "../../redux/Types";

// Utils
import {
  DRAWER_CONTENT_SETTINGS,
  DRAWER_CONTENT_CONTENT,
  DRAWER_CONTENT_MENU,
  DRAWER_CONTENT_STYLE,
} from "../../@global/const";
import { getPointBoxCollides } from "../../@utils/general";
import logger from "../../@global/logger";

// Components
import EditorDrawer from "../../@common/EditorDrawer";

/**
 * A top level building block of the Site Builder framework. It is responsible for
 * dynamically rendering pages's basic structure elements (i.e. Header and Footer)
 *
 * @module LayoutBlock
 *
 * @param {String} props.id - Layout block's id
 * @param {String} props.type - Layout block's type (header | footer)
 * @param {String} props.layout_component - A reference to the React Component responsible for rendering the block
 * @param {Array} props.content_items - A reference to the React Component responsible for rendering the block
 */
const LayoutBlock = (props) => {
  const {
    id,
    type,
    layout_component,
    content_items,
    dynamic_comp_folder,
    addNewBlockCb,
    is_edit_mode,
  } = props;

  const content_data = (content_items || [])[0] || {};
  const menu_id = (content_data.options || {}).menu_id || "";

  // Hooks
  const main_container_ref = React.useRef();
  const DynamicLayoutComp = React.useRef({});
  const DynamicSettingsComp = React.useRef({});
  const DynamicContentComp = React.useRef({});
  const StyleSelectorComp = React.useRef();
  const InstrumentsPanel = React.useRef();
  const AddBlockButton = React.useRef();
  const [instr_visible, setInstrVisible] = React.useState(false);
  const [show_editor_drawer, setShowEditorDrawer] = React.useState(false);
  const [drawer_content_type, setDrawerContentType] = React.useState();
  const [layout_block_state, setLayoutBlockSate] = React.useState();
  const dispatch = useDispatch();

  const [updateBlock] = useMutation(UPDATE_SITE_LAYOUT_BLOCK, {
    onError: (error) => {
      logger.error(error);
    },
  });

  const onShowEditingUI = React.useCallback(
    (type, data) => {
      if (type === DRAWER_CONTENT_MENU) {
        dispatch({
          type: SET_SHOW_MENUS_DRAWER,
          payload: true,
        });
        dispatch({
          type: SET_REQUESTED_MENU_ID,
          payload: menu_id,
        });
      } else {
        setDrawerContentType(type);
        setShowEditorDrawer(true);
      }
    },
    [dispatch, menu_id]
  );

  if (!layout_component) {
    logger.warn(`NO LAYOUT BLOCK COMPONENT PROVIDED!!! ${type} ${id}`);
  }

  // Dynamic imports

  StyleSelectorComp.current =
    StyleSelectorComp.current || React.lazy(() => import("./StyleSelector"));

  InstrumentsPanel.current = InstrumentsPanel.current || React.lazy(() => import("./Instruments"));

  AddBlockButton.current =
    AddBlockButton.current || React.lazy(() => import("../../@common/AddBlockButton"));

  DynamicLayoutComp.current[layout_component] =
    DynamicLayoutComp.current[layout_component] ||
    React.lazy(() =>
      import(`../../${dynamic_comp_folder}/${layout_component}`).catch((error) => {
        console.error(error);
        return import("./NullLayoutComp");
      })
    );

  DynamicSettingsComp.current[layout_component] =
    DynamicSettingsComp.current[layout_component] ||
    React.lazy(() =>
      import(`../../${dynamic_comp_folder}/${layout_component}/Settings`).catch((error) => {
        console.error(error);
        return import("./NullDrawerContent");
      })
    );

  DynamicContentComp.current[layout_component] =
    DynamicContentComp.current[layout_component] ||
    React.lazy(() =>
      import(`../../${dynamic_comp_folder}/${layout_component}/Content`).catch((error) => {
        console.error(error);
        return import("./NullDrawerContent");
      })
    );

  // Handlers
  const onDrawerClose = (e) => {
    e.persist();

    setShowEditorDrawer(false);

    if (main_container_ref.current) {
      const point = { x: e.clientX, y: e.clientY };
      const my_box = main_container_ref.current.getBoundingClientRect();

      if (!getPointBoxCollides(point, my_box)) {
        setInstrVisible(false);
      }
    }
  };

  // Render
  return (
    <MainContainer
      ref={main_container_ref}
      onMouseEnter={() => {
        is_edit_mode && setInstrVisible(true);
      }}
      onMouseLeave={() => {
        is_edit_mode && !show_editor_drawer && setInstrVisible(false);
      }}
    >
      {React.useMemo(() => {
        if (!layout_component) return null;

        const CurDynamicLayoutComp = DynamicLayoutComp.current[layout_component];

        return (
          <React.Suspense fallback={null}>
            <CurDynamicLayoutComp
              type={type}
              layout_block_state={layout_block_state || {}}
              setLayoutBlockSate={setLayoutBlockSate}
              content={content_data.content || {}}
              options={content_data.options || {}}
              showEditingUICb={onShowEditingUI}
              updateCb={(content, options) => {
                updateBlock({
                  variables: {
                    id: id,
                    content: content || content_data.content,
                    options: options || content_data.options,
                  },
                  optimisticResponse: {
                    __typename: "Mutation",
                    updateSiteLayout: {
                      __typename: "Site_layoutType",
                      id: id,
                      site_layout_contentItems: [
                        {
                          __typename: "Site_layout_contentType",
                          id: content_data.id,
                          content: content || content_data.content,
                          options: options || content_data.options,
                        },
                      ],
                    },
                  },
                });
              }}
            />
          </React.Suspense>
        );
      }, [
        updateBlock,
        layout_block_state,
        layout_component,
        type,
        content_data.id,
        content_data.content,
        content_data.options,
        id,
        onShowEditingUI,
      ])}

      {React.useMemo(() => {
        return (
          <>
            {instr_visible && is_edit_mode && (
              <React.Suspense fallback={null}>
                <InstrumentsPanel.current
                  component={layout_component}
                  showEditingUICb={onShowEditingUI}
                  hideBlockCb={() => {
                    logger.debug("Hide layout block");
                  }}
                />
                {type === "header" && (
                  <AddBlockButton.current
                    onMouseDown={(e) => addNewBlockCb({ show: true, weight: 0 })}
                  />
                )}
              </React.Suspense>
            )}
          </>
        );
      }, [instr_visible, is_edit_mode, type, addNewBlockCb, layout_component, onShowEditingUI])}

      {React.useMemo(() => {
        const CurDynamicSettingsComp = DynamicSettingsComp.current[layout_component];

        const CurDynamicContentComp = DynamicContentComp.current[layout_component];

        return (
          <>
            {is_edit_mode && (
              <EditorDrawer open={show_editor_drawer} onClose={onDrawerClose}>
                {drawer_content_type === DRAWER_CONTENT_STYLE && (
                  <React.Suspense fallback={null}>
                    <StyleSelectorComp.current block_id={id} type={type} closeCb={onDrawerClose} />
                  </React.Suspense>
                )}
                {drawer_content_type === DRAWER_CONTENT_SETTINGS && (
                  <React.Suspense fallback={null}>
                    <CurDynamicSettingsComp
                      content={content_data.content || {}}
                      options={content_data.options || {}}
                      closeCb={onDrawerClose}
                      updateCb={(options) => {
                        updateBlock({
                          variables: {
                            id: id,
                            options: options || content_data.options,
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            updateSiteLayout: {
                              __typename: "Site_layoutType",
                              id: id,
                              site_layout_contentItems: [
                                {
                                  __typename: "Site_layout_contentType",
                                  id: content_data.id,
                                  options: options || content_data.options,
                                },
                              ],
                            },
                          },
                        });
                      }}
                    />
                  </React.Suspense>
                )}
                {drawer_content_type === DRAWER_CONTENT_CONTENT && (
                  <React.Suspense fallback={null}>
                    <CurDynamicContentComp
                      content={content_data.content || {}}
                      options={content_data.options || {}}
                      closeCb={onDrawerClose}
                      updateCb={(content) => {
                        updateBlock({
                          variables: {
                            id: id,
                            content: content || content_data.content,
                          },
                          optimisticResponse: {
                            __typename: "Mutation",
                            updateSiteLayout: {
                              __typename: "Site_layoutType",
                              id: id,
                              site_layout_contentItems: [
                                {
                                  __typename: "Site_layout_contentType",
                                  id: content_data.id,
                                  content: content || content_data.content,
                                },
                              ],
                            },
                          },
                        });
                      }}
                    />
                  </React.Suspense>
                )}
              </EditorDrawer>
            )}
          </>
        );
      }, [
        is_edit_mode,
        id,
        type,
        layout_component,
        show_editor_drawer,
        drawer_content_type,
        content_data.id,
        content_data.content,
        content_data.options,
        updateBlock,
      ])}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    is_edit_mode: state.Editor.is_edit_mode,
  };
};

export default connect(mapStateToProps, {})(LayoutBlock);

LayoutBlock.whyDidYouRender = {
  logOnDifferentValues: false,
};
