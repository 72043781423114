import styled from "styled-components";

import Modal from "@material-ui/core/Modal";

export const MainContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    outline: 0px;
  }
`;
