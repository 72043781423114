import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(p) => p.theme.palette.aria.grey_dark};
  color: white;

  padding: 10px;

  .page-status {
    display: flex;
    align-items: center;

    padding-right: 32px;
    border-right: 1px solid ${(p) => p.theme.palette.aria.divider};
  }

  .version-status {
    display: flex;
    align-items: center;

    padding-left: 32px;
  }

  .MuiButton-root {
    height: 20px;
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .MuiButton-label {
    font-size: 0.75rem;
    height: 8px;
    color: ${(p) => p.theme.palette.aria.primary};
  }
`;
