import React from "react";
import PropTypes from "prop-types";

// Mui
import { MainContainer } from "./styles";
import { useTheme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Description
 * @module GlobalSpinner
 *
 * @param {type} props.prop_name - Description
 */

const GlobalSpinner = (props) => {
  const { show } = props;

  // Hooks
  const theme = useTheme();

  return (
    <MainContainer
      theme={theme}
      open={show}
      closeAfterTransition
      disableRestoreFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={show}>
        <div className="content">
          <CircularProgress className="editing-ui" />
        </div>
      </Fade>
    </MainContainer>
  );
};

GlobalSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default GlobalSpinner;
