export const fonts_options = ["Roboto", "Montserrat", "Nunito", "Bitter"];

export const admin_bar_height = 50;

export const default_theme_obj = {
  typography: {
    fontSize: 14,
  },
  palette: {
    aria: {
      primary: "#6488FF",
      secondary: "#17ADDE",
      success: "#99C24D",
      warning: "#F18F01",
      error: "#ED6A5A",
      grey_light: "#BFC3C7",
      grey_semi: "#959BA2",
      grey: "#6A727C",
      grey_dark: "#2A3644",
      grey_darker: "#283340",
      background_primary: "#fafafa",
      text_primary: "#303030",
      text_secondary: "#76797B",
      divider: "#eaebec",
    },
  },
  zIndex: {
    bg_image_btn: 110,
    edit_mode_border: 400,
    block_instruments: 410,
    drop_down_menu: 450,
    add_block_btns: 500,
    header: 510,
    edit_textfiled_ctrls: 520,
    admin_bar: 600,
    popper_portal: 700,
    portal_overlay: 900,
  },
};

export const page_name_error_msgs = {
  allowed_chars: "Must contain only letters, numbers or spaces",
  exists: "Page with such name already exists",
};

export const page_uri_error_msgs = {
  allowed_chars: "Must contain only letters or numbers",
  exists: "Page with such URL already exists",
};

export const DRAWER_CONTENT_SETTINGS = "DRAWER_CONTENT_SETTINGS";
export const DRAWER_CONTENT_CONTENT = "DRAWER_CONTENT_CONTENT";
export const DRAWER_CONTENT_MENU = "DRAWER_CONTENT_MENU";
export const DRAWER_CONTENT_STYLE = "DRAWER_CONTENT_STYLE";
export const INSTRUMENTS_DUPLICATE = "INSTRUMENTS_DUPLICATE";
export const INSTRUMENTS_DELETE = "INSTRUMENTS_DELETE";
export const INSTRUMENTS_UP = "INSTRUMENTS_UP";
export const INSTRUMENTS_DOWN = "INSTRUMENTS_DOWN";

export const bg_color_options = [
  { label: "Primary", path: "primary.main" },
  { label: "Secondary", path: "secondary.main" },
  { label: "Default Background", path: "background.default" },
  { label: "Black", path: "common.black" },
  { label: "White", path: "common.white" },
  { label: "Light grey", path: "grey.400" },
  { label: "Grey", path: "grey.600" },
  { label: "Dark grey", path: "grey.800" },
];

export const txt_color_options = [
  { label: "Primary contrast", path: "primary.contrastText" },
  { label: "Secondary contrast", path: "secondary.contrastText" },
  { label: "Black", path: "common.black" },
  { label: "White", path: "common.white" },
  { label: "Light grey", path: "grey.400" },
  { label: "Grey", path: "grey.600" },
  { label: "Dark grey", path: "grey.800" },
];

export const TOUR_SETTINGS = "TOUR_SETTINGS";
export const TOUR_MNG_PAGES = "TOUR_MNG_PAGES";
export const TOUR_MNG_MENUS = "TOUR_MNG_MENUS";
export const TOUR_DEVICES = "TOUR_DEVICES";
export const TOUR_VERSIONS = "TOUR_VERSIONS";
export const TOUR_SAVE = "TOUR_SAVE";
export const TOUR_ADD_EDITOR = "TOUR_ADD_EDITOR";
export const TOUR_SHARE = "TOUR_SHARE";
export const TOUR_ADD_PAGE = "TOUR_ADD_PAGE";
export const TOUR_EDIT_MODE = "TOUR_EDIT_MODE";
