import React from "react";

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    collapseGroups: true,
    logOwnerReasons: true,
    trackHooks: false,
    exclude: [
      /^RouterImpl/,
      /^ChildWrapper/,
      /^Connect/,
      /^ResizeDetector/,
      /^TouchRipple/,
      /^CancelIcon/,
      /^List/,
    ],
  });
}
