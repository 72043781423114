import React from "react";

// Apollo GraphGL
import { useQuery } from "@apollo/client";

import { SITE_LAYOUT } from "../../@global/queries";

// Redux
import { connect } from "react-redux";

// MUI
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { MainContainer, LayoutHeader, LayoutFooter } from "./styles";

// Utils
import { getRenderAdminUI, onRequestError } from "../../@utils/apollo";
import { getSiteId } from "../../@utils/general";
import { version } from "../../../package.json";

// Components
import LayoutBlock from "../LayoutBlock";
import PageRouter from "../PageRouter";

const SiteLayout = (props) => {
  const { header_height, is_edit_mode, bearer_token, is_admin, is_editor, home_uri } = props;

  // Hooks
  const [cur_page_data, setCurrentPageData] = React.useState([]);
  const [show_tour, setShowTour] = React.useState(
    !(JSON.parse(window.localStorage.getItem("completed_tour")) || {})[version]
  );
  const is_mobile = useMediaQuery("(hover: none) and (pointer: coarse)");
  const theme = useTheme();
  const AdminUI = React.useRef();
  const ProductTourUI = React.useRef();

  const [page_block_selector_state, setPageBlockSelectorState] = React.useState({
    show: false,
    weight: 0,
  });

  const layout = useQuery(SITE_LAYOUT, {
    onError: onRequestError,
    variables: {
      site_id: getSiteId(),
    },
  });

  const site_layout_data = (layout.data || {}).site_layoutItems || [];

  // Sort layout blocks, based on weight (order)
  const layout_blocks = site_layout_data
    .map((layout_block) => ({ ...layout_block }))
    .sort((a, b) => a.weight - b.weight);

  const hidden_page_layout = cur_page_data.page_layoutItems || [];
  const page_found = Object.entries(cur_page_data).length > 0;
  const hidden_blocks = {};

  layout_blocks.forEach((block) => {
    const hidden_block = hidden_page_layout.find((item) => item.site_layout_id === block.id);

    hidden_blocks[block.type] = false;

    if (page_found && !hidden_block) hidden_blocks[block.type] = false;
  });

  const cur_header_height = hidden_blocks["header"] ? 0 : header_height;

  const render_admin_bar = getRenderAdminUI(bearer_token, is_admin, is_editor);

  AdminUI.current = AdminUI.current || React.lazy(() => import("../AdminUI"));
  ProductTourUI.current = ProductTourUI.current || React.lazy(() => import("../ProductTour"));

  // Render
  return (
    <MainContainer theme={theme} header_height={cur_header_height}>
      {React.useMemo(
        () => (
          <>
            {!is_mobile && (
              <div className={`admin-bar ${is_edit_mode && "edit-mode"}`}>
                {render_admin_bar && (
                  <React.Suspense fallback={null}>
                    <AdminUI.current />
                  </React.Suspense>
                )}

                {is_edit_mode && show_tour && (
                  <React.Suspense fallback={null}>
                    <ProductTourUI.current
                      onEndTour={() => {
                        window.localStorage.setItem(
                          "completed_tour",
                          JSON.stringify({
                            ...window.localStorage.getItem("completed_tour"),
                            [version]: true,
                          })
                        );

                        setShowTour(false);
                      }}
                    />
                  </React.Suspense>
                )}
              </div>
            )}
          </>
        ),
        [is_edit_mode, is_mobile, render_admin_bar, show_tour, setShowTour]
      )}

      {layout_blocks.map((layout_block_data, index) => {
        switch (layout_block_data.type) {
          case "header":
            return (
              <React.Fragment key={index}>
                {!hidden_blocks["header"] && (
                  <LayoutHeader theme={theme} className={is_edit_mode && "edit-mode"}>
                    <LayoutBlock
                      id={layout_block_data.id}
                      type={layout_block_data.type}
                      layout_component={layout_block_data.layout_component}
                      content_items={layout_block_data.site_layout_contentItems}
                      dynamic_comp_folder="header.blocks"
                      addNewBlockCb={setPageBlockSelectorState}
                    />
                  </LayoutHeader>
                )}
              </React.Fragment>
            );
          case "content":
            return (
              <React.Fragment key={index}>
                <PageRouter
                  home_uri={home_uri}
                  page_block_selector_state={page_block_selector_state}
                  setPageBlockSelectorState={setPageBlockSelectorState}
                  setCurrentPageData={setCurrentPageData}
                />
              </React.Fragment>
            );
          case "footer":
            return (
              <React.Fragment key={index}>
                {!hidden_blocks["footer"] && (
                  <LayoutFooter theme={theme}>
                    <LayoutBlock
                      id={layout_block_data.id}
                      type={layout_block_data.type}
                      layout_component={layout_block_data.layout_component}
                      content_items={layout_block_data.site_layout_contentItems}
                      dynamic_comp_folder="footer.blocks"
                      addNewBlockCb={setPageBlockSelectorState}
                    />
                  </LayoutFooter>
                )}
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    current_page_id: state.Nav.current_page_id,
    header_height: state.Layout.header_height,
    is_edit_mode: state.Editor.is_edit_mode,
    bearer_token: state.User.token,
    is_admin: state.UI.is_admin,
    is_editor: state.UI.is_editor,
  };
};

export default connect(mapStateToProps)(SiteLayout);

SiteLayout.whyDidYouRender = {
  logOnDifferentValues: false,
};
