import { SET_SITE_EDIT_MODE, SET_PAGE_VERSION_ID, EVICT_PAGE_VERSION } from "../Types";

const initialState = {
  is_edit_mode: false,
  page_ver_id_lookup: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SITE_EDIT_MODE:
      return {
        ...state,
        is_edit_mode: payload,
      };
    case SET_PAGE_VERSION_ID:
      return {
        ...state,
        page_ver_id_lookup: {
          ...state.page_ver_id_lookup,
          [payload.page_id]: payload.ver_id,
        },
      };
    case EVICT_PAGE_VERSION:
      return {
        ...state,
        page_ver_id_lookup: {
          ...state.page_ver_id_lookup,
          [payload.page_id]: null,
        },
      };
    default:
      return state;
  }
}
