import React from 'react';

// Components
import PageStatusBar from "./PageStatusBar";

// Apollo GraphGL
import { useLazyQuery} from "@apollo/client";
import { DOCS_ALL_TAGS } from "../../@global/queries";

// REACT SELECT
import makeAnimated from 'react-select/animated';

// MUI
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {InputLabel, Grid} from "@material-ui/core"
import Select from "react-select"

// Style
import { TagFilter } from "./styles";

// Filter functions
import {filterData, bundleSelectTags, isFilterBoxByTagIsExists, isFilterBoxHaveGroups, getFilterBoxByTagOptions, getFilterByTagOptions, isOtherGroupOfTagsEnabled, getBlockData} from "./handlers";

// General functions
import {isDefined, isEmptyName, isEmptyArray, getPaletteColor } from "../../@utils/general";

function FilterBlock({ is_edit_mode, data, page_data, page_blocks_data, setPage_version_data }) {

  // Hooks
  const theme = useTheme();
  const is_mobile = useMediaQuery("(hover: none) and (pointer: coarse)");
  const animatedComponents = makeAnimated();

  const [tagList, setTagList] = React.useState([]); // State variables - to store all tags into `tagList`
  const [isFilterByTagExist, setIsFilterByTagExist] = React.useState(false); // State variables - intial values is false. Check if a page/s have filter box 
  const [isFilterByTagHaveGroupOfTags, setIsFilterByTagHaveGroupOfTags] = React.useState(false); // State variables - intial values is false. Check if a page/s have filter box and group of Tags
  const [filterBoxByTagOptions, setFilterBoxByTagOptions] = React.useState(false); // State variables - intial values is empty array. Fetch options values for "filter by tag" box 
  const [pageBlockItems, setPageBlockItems] = React.useState([]); // State variables - intial values for all blocks in a page
  const [groupOfTags, setGroupOfTags] = React.useState([]); // State variables - intial values of group of tags
  const [displayOtherGroupOfTags, setDisplayOtherGroupOfTags] = React.useState(false); // State variables - intial values of group of tags
  const [selectedTags, setSelectedTags] = React.useState([]);
  
  const [getTags] = useLazyQuery(DOCS_ALL_TAGS, { // Fetch all tags using GraphQl query `DOCS_ALL_TAGS`
    onCompleted: (data) => {
      setTagList(data.tagItems || []);
    },
  });

/**
 * Fetch group of tags (if exists)
 *
 * @property {array} data
 * @returns {array}             - return an array of options' data, or empty array (if data not exists)
 */
 const getFilterBoxGroupOfTags = async (data) => {
  if(!isDefined(data)){
      return [];
  } else {
      const page_version_data = (data.page_versionItems || [])[0] || {};
      const options = await getFilterByTagOptions(page_version_data.page_version_blockItems);  // the function is paused here until the promise is fulfilled
      if(isDefined(options) && isDefined(options.group_tags) && options.group_tags.length>0){
        let groupOfTags = options.group_tags;
        let groups = [];
        let notAssignedGroupOfTags = []; // This will store all tags that are not existing in the group of tags
        
        if (isDefined(groupOfTags)) {
          Object.keys(tagList).map(indexOfTagList => {
            let isTagAssigned = false;
            Object.keys(groupOfTags).map(indexOfGroupOfAssignedTags => {
              groupOfTags[indexOfGroupOfAssignedTags].tags.forEach(tag => {
                if (tagList[indexOfTagList].id === tag.id) {
                  isTagAssigned = true;
                }
              });
            });
        
            if (!isTagAssigned) {
              notAssignedGroupOfTags.push(tagList[indexOfTagList]);
            }
          });
        } else {
          notAssignedGroupOfTags = tagList;
        }
        
        let updatedGroupOfTags = [...groupOfTags, { 'group_name': 'Other group of tags', 'tags':notAssignedGroupOfTags }];

            const groupedTags = {};
            const processedData = updatedGroupOfTags.map(group => {
              if(isDefined(group)){
                group.tags.forEach((tag) => {
                  if (groupedTags[tag.tag]) {
                    // This tag have the same document name, so we need to push the document_id only
                    groupedTags[tag.tag].document_id.push(tag.document_id);
                  } else {
                    groupedTags[tag.tag] = {
                      __typename: tag.__typename,
                      id: tag.id,
                      tag: tag.tag,
                      document_id: [tag.document_id],
                    };
                  }
                });
              
                const processedTags = Object.values(groupedTags);
                let tags = [];
                
                group.tags.forEach((tag) => {
                  processedTags.forEach((processedTag) => {
                    if(tag.tag === processedTag.tag){
                      if (!tags.includes(processedTag)) {
                        tags.push(processedTag);
                      }
                    }
                  });
                });
                
                return {
                  group_name: group.group_name,
                  tags: tags
                };
              }
            });

            processedData.map((group) => {
              if(!isEmptyName(group.group_name) && !isEmptyArray(group.tags)){
                  // Assign the name of the group and the tags into an array
                  const groupName = group.group_name;
                  const tags = group.tags;
                  groups[groupName] = tags;
              }
          });

          let removeDuplicationIndexes = [];
          if(isDefined(groups['Other group of tags'])){
            Object.keys(groups['Other group of tags']).map(otherGroupOfTagsIndex => {
              const otherGroupTag = groups['Other group of tags'][otherGroupOfTagsIndex];
            
              Object.keys(groups).map(groupName => {
                if(groupName !== "Other group of tags"){
                  Object.keys(groups[groupName]).map(index => {
                    const assignedTag = groups[groupName][index];
                      if(assignedTag === otherGroupTag){
                        removeDuplicationIndexes.push(otherGroupOfTagsIndex);
                      }
                  })
                }
              });
            });
          }


          let removeDuplicationIndexesUniqueArray = [...new Set(removeDuplicationIndexes)];          

          // / Convert removeDuplicationIndexesUniqueArray elements from string to number
          const indexesToRemove = removeDuplicationIndexesUniqueArray.map(index => parseInt(index));

          // Sort indexes in descending order to remove elements from the end
          indexesToRemove.sort((a, b) => b - a);

          // Remove elements from the groups['Other group of tags'] array
          indexesToRemove.forEach(index => groups['Other group of tags'].splice(index, 1));
          

          return groups;
      }else{
          return [];
      }
    }
  };

  /**
   * Filter data function
   *
   * @property {string} tag              - tag name that we woulk like to filter data through
   * @property {array} page_blocks_data  - all blocks' content in a page
   * @property {object} event            - determines if a checkbox is checked or not 
   * @returns {array}                    - return a new block data that filter out the page blocks data ("page_blocks_data") based on the passed tag name (tag)
  */

  const handleChange = (tag, page_blocks_data, event) => {
    event.persist(); // This will remove the synthetic event from the pool, preventing it from being reused, and ensure that its properties are retained even after the event handler function has completed.

    setSelectedTags(prevTags => {
        const updatedTags =  event.target.checked ? [...prevTags, tag] : prevTags.filter(t => t.id !== tag.id);
        const blockItems = filterData(pageBlockItems, updatedTags);
        setPage_version_data({
          ...page_blocks_data,
          page_version_blockItems: isEmptyArray(updatedTags)? pageBlockItems : blockItems
        });
        return updatedTags;
    });

    
  }
  
    

    const handleMobileSelect = (groupName, tag) => {
      if (tag.length) {
        // If there are selected tags
        setSelectedTags((prevSelectedTags) => {
            const updatedSelectedTags = {
              ...prevSelectedTags,
              [groupName]: tag.map(tag => tag.value),
            };
            const blockItems = filterData(pageBlockItems, updatedSelectedTags);
            setPage_version_data({
              ...page_blocks_data,
              page_version_blockItems: isEmptyArray(updatedSelectedTags)? pageBlockItems : blockItems
            });
            return updatedSelectedTags;
        })      
      } else {
          setSelectedTags((prevSelectedTags) => {
            // Remove the entire entry for groupName
            const { [groupName]: removedGroup, ...restOfSelectedTags } = prevSelectedTags;
            const updatedSelectedTags = { ...restOfSelectedTags };
            const blockItems = filterData(pageBlockItems, updatedSelectedTags);
            setPage_version_data({
              ...page_blocks_data,
              page_version_blockItems: isEmptyArray(updatedSelectedTags)? pageBlockItems : blockItems
            });
            return updatedSelectedTags;
          });
        }
    };

    const handleTagSelect = (groupName, tag) => {
      if (selectedTags[groupName]) {
        const groupArray = selectedTags[groupName]
        //if the tag doesnt exist in the group
        if (!groupArray.some(currentTag => currentTag.id === tag.id)) {
          setSelectedTags((prevSelectedTags) => {
            const updatedSelectedTags = {
              ...prevSelectedTags,
              [groupName]: [...groupArray, tag]
            };
            const blockItems = filterData(pageBlockItems, updatedSelectedTags);
            setPage_version_data({
              ...page_blocks_data,
              page_version_blockItems: isEmptyArray(updatedSelectedTags)? pageBlockItems : blockItems
            });
            return updatedSelectedTags;
          });
        } else {
          setSelectedTags((prevSelectedTags) => {
            const indexToRemove = groupArray.findIndex(obj => obj.id === tag.id)
            const updatedSelectedTags = [...groupArray];
            updatedSelectedTags.splice(indexToRemove, 1);
            const blockItems = filterData(pageBlockItems, updatedSelectedTags);
            setPage_version_data({
              ...page_blocks_data,
              page_version_blockItems: isEmptyArray(updatedSelectedTags)? pageBlockItems : blockItems
            });
            return {...prevSelectedTags, [groupName] : updatedSelectedTags}
          });
        }
      } else {
        // Handle the case when groupName doesn't exist in selectedTags
        setSelectedTags((prevSelectedTags) => {
          // Add the new group with the current tag
          const updatedSelectedTags = {
            ...prevSelectedTags,
            [groupName]: [tag],
          };
          const blockItems = filterData(pageBlockItems, updatedSelectedTags);
          setPage_version_data({
            ...page_blocks_data,
            page_version_blockItems: isEmptyArray(updatedSelectedTags)? pageBlockItems : blockItems
          });
          return updatedSelectedTags;
        });
      }
    };

    // useCallback is a React hook
  // is used for "loading","data", and "error" child component that are rendering repeatedly without the need for it
  const getLoadedData = React.useCallback(async () => {
      if(data){
        const page_version_data = getBlockData(data, is_edit_mode); // * getBlockData will return the current page contents
        const isFilterBoxExist = await isFilterBoxByTagIsExists(data);
        const isFilterBoxExistAndHaveGroupOfTags = await isFilterBoxHaveGroups(data);
        const filterBoxByTagOptions = await getFilterBoxByTagOptions(data);
        const allGroupOfTagsData = await getFilterBoxGroupOfTags(data);
        const isDisplayOtherGroupOfTags = await isOtherGroupOfTagsEnabled(data);
        setDisplayOtherGroupOfTags(isDisplayOtherGroupOfTags);

        let notAssignedGroupOfTags = []; // This will store all tags that are not existing in the group of tags

        if(isDefined(allGroupOfTagsData['Assigned Groups'])){
          const assignedGroups = allGroupOfTagsData['Assigned Groups'];
          
          Object.keys(tagList).map((indexOfTagList) => {
            let isTagAssigned = false;
            Object.keys(allGroupOfTagsData['Assigned Groups']).map((indexOfGroupOfAssignedTags) => {
              if(tagList[indexOfTagList].id === (assignedGroups[indexOfGroupOfAssignedTags].id)){
                isTagAssigned = true;
              }
            });

            if(!isTagAssigned){
              notAssignedGroupOfTags.push(tagList[indexOfTagList]);
            }
          });
        }else{
          notAssignedGroupOfTags = tagList;
        }

        let allGroups = [];
        Object.keys(allGroupOfTagsData).map((indexOfGroupOfTags) => {
          if(indexOfGroupOfTags !== 'Assigned Groups'){
            const groupName = indexOfGroupOfTags;
            const tags = allGroupOfTagsData[indexOfGroupOfTags];
            allGroups[groupName] = tags;
          }
        });
        if( !displayOtherGroupOfTags){ // Once the checkbox "Display other group of tags" is checked, show all none assigned group of tags  
          delete allGroups["Other group of tags"];
        }

        setIsFilterByTagExist(isFilterBoxExist);
        setIsFilterByTagHaveGroupOfTags(isFilterBoxExistAndHaveGroupOfTags);
        setGroupOfTags(allGroups);
        setFilterBoxByTagOptions(filterBoxByTagOptions);
        setPage_version_data({...page_version_data});
        setPageBlockItems(page_version_data.page_version_blockItems);
      }    
  }, [data, is_edit_mode, tagList, displayOtherGroupOfTags]); // every time "loading","data", "error" and/or "is_edit_mode" changed, the function will loaded again

  React.useEffect(() => {
    getTags({
      variables: {tag :""}, // this parameters will send to DOCS_ALL_TAGS GraphQl query where `tag` is the tag's name
    });
    getLoadedData();
  }, [getLoadedData, getTags]); // useEffect will run once and when loading, data, and/or error changes
  
  return (
    <>
      <TagFilter
          theme={theme}
          bg_color={isDefined(filterBoxByTagOptions) ? getPaletteColor(filterBoxByTagOptions.bg_color, theme) : ""}
        >
          {!is_edit_mode && isFilterByTagExist && tagList && !isFilterByTagHaveGroupOfTags &&(
            <div className="checkbox-filter-group row">
              
              <h3 className="col-12">
                Filter by document's tags:
              </h3>
              
              <i className="fa-duotone fa-camera-retro">  </i>
              {tagList.map((tag, index) =>
                !tag.tag ? null : (
                  <div className="form-check col-3" key={index}>
                    <input 
                      className="form-check-input"
                      type="checkbox"
                      value={tag.tag}
                      onChange={(event)=>handleChange(tag, page_blocks_data, event)}
                      />
                    <label className="form-check-label">
                      {tag.tag}
                    </label>
                  </div>
                )
              )}
            </div>
          )}

          
          {!is_edit_mode && isFilterByTagExist && tagList && isFilterByTagHaveGroupOfTags &&(
            <div className={is_mobile ? "checkbox-filter-group-mobile row" : "checkbox-filter-group row"} >
              
              <h3 className="col-12">
                Filter by document's tags:
              </h3> 
              
              <i className="fa-duotone fa-camera-retro"> </i>

              
                {!is_mobile ? Object.keys(groupOfTags).map((groupName) => (
                  <div className="col-3" key={groupName}>
                    <p className="groupOfTag-title">{groupName}</p>
                    {groupOfTags[groupName].map((tag, index) =>
                      !tag.tag ? null : (
                        <div className="form-check" key={index}>
                          <input 
                            className="form-check-input"
                            type="checkbox"
                            value={tag.tag}
                            onChange={()=>handleTagSelect(groupName, tag)}
                          />
                          <label className="form-check-label">
                            {tag.tag}
                          </label>
                        </div>
                      )
                    )}
                  </div>
                )) :
                  <Grid container spacing={2}>
                    {Object.entries(groupOfTags).map(([groupName, groupValues]) => (
                      <Grid item key={groupName} xs={12}>
                        <InputLabel>
                          {groupName}
                        </InputLabel>
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          options={bundleSelectTags(groupValues)}
                          onChange={(e) => handleMobileSelect(groupName, e)}
                          style={{width: '100%'}}
                        />
                      </Grid>
                      )
                    )}
                  </Grid>
                }
            </div>
          )}
          {is_edit_mode && (
            <PageStatusBar
              page_name={page_data.name}
              page_visibility={page_data.visibility}
              pageCb={() => {}}
              version_active={page_blocks_data.active}
              version_nr={page_blocks_data.version}
              version_id={page_blocks_data.id}
            />
          )}
        </TagFilter>
    </>
  );
}

export default FilterBlock;