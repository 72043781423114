// General functions
import {isDefined, isEmptyName, isEmptyArray, distinctArray} from  "../../@utils/general";

/**
 * Filter out the block items based on tag
 *
 * @property {array} blockItems - all blocks' content in a page
 * @property {object} tag       - All the tag selected, separated by their group
 * @returns {array}             - return a new block data that contains the passed tag name (tag)
 */
export const filterData = (blockItems, selectedTags = []) => {
    if (isDefined(blockItems) && blockItems.length !== 0) {
        const new_blockItems = [...blockItems]; // Make a copy for the existing block items
        let block = [];
        let blockIndexes = [];
        const tagsDocumentIds = getTagsDocumentIds(selectedTags); // get all documentIds into 1 array
        new_blockItems.map((blockItem, index) => {

            if (isDefined(blockItem.page_version_block_contentItems)) {
                blockItem.page_version_block_contentItems.map((block_contentItem) => {
                    if (isDefined(block_contentItem.options) && isDefined(block_contentItem.options.files)) { // if block contains file/s 
                        block_contentItem.options.files.map((file) => {
                            if (isDefined(file.id) && tagsDocumentIds.indexOf(file.id) !== -1) {
                                if (!blockIndexes.includes(index))
                                    blockIndexes.push(index); // push an index for the tag into `blockIndexes` array
                            }
                        });
                    } else if (isDefined(block_contentItem.options) && isDefined(block_contentItem.options.filter) && (block_contentItem.options.filter === "filter.by.tag")) { // if block contains "Filter by tag" block, then we don't need to remove it
                        blockIndexes.push(index); // push an index for the filter block
                    }
                });
            }
        });
        blockIndexes.map((i) => {
            block.push(new_blockItems[i]);
        });
        return block;
    }
};

/**  Needed for (mobile) Select component for tag selection
 * @param {arr} groupTags - array of tags for a given group
 * @returns {arr}         - array of objects that exposes the name of the tag, plus everything else
*/
export const bundleSelectTags = (groupTags) => {
    return groupTags.map(tag => {
        return {
            label: tag.tag,
            value: tag
        }
    })
}

// Function to check if the array element is of type 'TagType'
function isTagType(element) {
    return element && typeof element === 'object' && element.hasOwnProperty('__typename') && element.__typename === 'TagType';
}

// Function to check if the "Filter by document's tags" is grouped or not
function isNotGroupOfTags(array) {
    if (Array.isArray(array) && array.length > 0) {
        return isTagType(array[0]);
    }
    return false;
}

// Takes all tags from object and puts all document_ids into a single array
const getTagsDocumentIds = (tags) => {
        if(isNotGroupOfTags(tags)){
            if (Array.isArray(tags)) {
                const documentIds = tags.map(item => item.document_id);
                return [...new Set(documentIds)];
            } else {
                return [];
            }
        } else {

            const documentIds = [];
            for (const key in tags) {
                if (tags.hasOwnProperty(key)) {
                    for (const tagArray of tags[key]) {
                        if (tagArray.hasOwnProperty('document_id')) {
                            documentIds.push(tagArray['document_id']);
                        }
                    }
                }
            }
            // Flatten the array
            const flattenedDocumentIds = [...new Set(documentIds.flat())];
            return flattenedDocumentIds;
        }
  };
  
  
async function isFilterByTagIsExists(blockItems) {
    if(blockItems !== null && blockItems.length !== 0){
        let flag = false;
        blockItems.map((blockItem)=> {
            if(isDefined(blockItem.page_version_block_contentItems)){
                blockItem.page_version_block_contentItems.map((block_contentItem)=>{
                    if(isDefined(block_contentItem.options) && isDefined(block_contentItem.options.filter) && (block_contentItem.options.filter === "filter.by.tag")){ // if block contains "Filter by tag" block
                        flag = true; // means the page contents have filter box in the page
                    }
                });
            }
        });
        return flag;
    }
}

// Check if "Filter by document's tags" box, have group of tags or not
async function isFilterByTagHaveGroup(blockItems) {
    if(blockItems !== null && blockItems.length !== 0){
        let flag = false;
        blockItems.map((blockItem)=> {
            if(isDefined(blockItem.page_version_block_contentItems)){
                blockItem.page_version_block_contentItems.map((block_contentItem)=>{
                    if(isDefined(block_contentItem.options) && isDefined(block_contentItem.options.filter) && (block_contentItem.options.filter === "filter.by.tag")){ // if block contains "Filter by tag" block
                        if(isDefined(block_contentItem.options.group_tags) && block_contentItem.options.group_tags.length>1){
                            flag = true; // means the page contents group of tags
                        }
                    }
                });
            }
        });
        return flag;
    }
}

export async function getFilterByTagOptions(blockItems) {
    if(blockItems !== null && blockItems.length !== 0){
        let options = [];
        blockItems.map((blockItem)=> {
            if(isDefined(blockItem.page_version_block_contentItems)){
                blockItem.page_version_block_contentItems.map((block_contentItem)=>{
                    if(isDefined(block_contentItem.options) && isDefined(block_contentItem.options.filter) && (block_contentItem.options.filter === "filter.by.tag")){ // if block contains "Filter by tag" block
                        options = block_contentItem.options; // means the page contents have filter box in the page
                    }
                });
            }
        });
        return options;
    }
}

// Check if "Filter by document's tags" box, have enabled the checkbox "Display Other Group Of Tags"
async function isOtherGroupOfTagsChecked(blockItems) {
    if(blockItems !== null && blockItems.length !== 0){
        let flag = false;
        blockItems.map((blockItem)=> {
            if(isDefined(blockItem.page_version_block_contentItems)){
                blockItem.page_version_block_contentItems.map((block_contentItem)=>{
                    if(isDefined(block_contentItem.options) && isDefined(block_contentItem.options.filter) && (block_contentItem.options.filter === "filter.by.tag")){ // if block contains "Filter by tag" block
                        if(isDefined(block_contentItem.options.display_other_group_of_tags)){
                            flag = block_contentItem.options.display_other_group_of_tags; // This will return true/f`alse
                        }
                    }
                });
            }
        });
        return flag;
    }
}


export const isFilterBoxByTagIsExists = async (data) => {
    if(!isDefined(data)){
        return false;
    }else{
        const page_version_data = (data.page_versionItems || [])[0] || {};
        let isExist = await isFilterByTagIsExists(page_version_data.page_version_blockItems);  // the function is paused here until the promise is fulfilled
        return isExist ? true : false;
    }
};

/**
 * Fetch filter option data that exisits in a page, e.g. 'height', 'bg_color', and 'filter'
 *
 * @property {array} data
 * @property {string} tag       - tag name that we woulk like to filter data through
 * @returns {array}             - return an array of options' data, or empty array (if data not exists)
 */
export const getFilterBoxByTagOptions = async (data) => {
    if(!isDefined(data)){
        return [];
    }else{
        const page_version_data = (data.page_versionItems || [])[0] || {};
        const options = await getFilterByTagOptions(page_version_data.page_version_blockItems);  // the function is paused here until the promise is fulfilled
        return options;
    }
};

/**
 * Check if a data have a Filter by document box, and there is group of tags 
 *
 * @property {array} data
 * @returns {array}             - return an true if there is a group of tags, false otherwise
 */
 export const isFilterBoxHaveGroups = async (data) => {
    if(!isDefined(data)){
        return false;
    }else{
        const page_version_data = (data.page_versionItems || [])[0] || {};
        let isExist = await isFilterByTagHaveGroup(page_version_data.page_version_blockItems);  // the function is paused here until the promise is fulfilled
        return isExist ? true : false;
    }
};



/**
 * Fetch group of tags (if exists)
 *
 * @property {array} data
 * @returns {array}             - return an array of options' data, or empty array (if data not exists)
 */
 export const getFilterBoxGroupOfTags = async (data) => {
    if(!isDefined(data)){
        return [];
    }else{
        const page_version_data = (data.page_versionItems || [])[0] || {};
        const options = await getFilterByTagOptions(page_version_data.page_version_blockItems);  // the function is paused here until the promise is fulfilled
        if(isDefined(options.group_tags) && options.group_tags.length>0){
            const groupOfTags = options.group_tags;
            let groups = [];
            let assignedGroupTags = []; // This will store all tags that are existing in the groups
            groupOfTags.map((group) => {
                if(!isEmptyName(group.group_name) && !isEmptyArray(group.tags)){
                    // Assign the name of the group and the tags into an array
                    const groupName = group.group_name;
                    const tags = group.tags;
                    groups[groupName] = tags;

                    // We need to store all other tags into "Other Groups"
                    // So we stored all tags that are assigned to groups, then excluding them from all other tags
                    const union = [...tags, ...assignedGroupTags]; // Union between all group of tags
                    assignedGroupTags = union;
                }
            });
            if(!isEmptyArray(assignedGroupTags)){
                // Remove the duplicates tags
                const distinctAssignedGroupTags = distinctArray(assignedGroupTags);
                groups['Assigned Groups'] = distinctAssignedGroupTags;
            }
            return groups;
        }else{
            return [];
        }
    }
};


/**
 * Check if "Display Other Group Of Tags" checkbox is enabled or not
 *
 * @property {array} data
 * @returns {array}             - return True if the checkbox is checked, false otherwise
 */
 export const isOtherGroupOfTagsEnabled = async (data) => {
    if(!isDefined(data)){
        return false;
    }else{
        const page_version_data = (data.page_versionItems || [])[0] || {};
        let isChecked = await isOtherGroupOfTagsChecked(page_version_data.page_version_blockItems);  // the function is paused here until the promise is fulfilled
        return isChecked ? true : false;
    }
};


 /**
   * Get the current page contents
   *
   * @property {object} data - 
   * @returns {array}        - return page contents if exists
  */
  export const getBlockData = (data, is_edit_mode) => {
    if(data !== null && data.length !==0){
      let newBlocks = [];
      let blockIndexes = [];
      const page_version_data = (data.page_versionItems || [])[0] || {};
      if(is_edit_mode){
        return page_version_data;
      }else{
        if(isDefined(page_version_data)){
          // We need to discard the filter box of being showing in the page
          // As long we can't modify the object (page_version_data), so we've created a copy of it, then we modified the new created object
          // We've assigned the old object to the new object. Initially, we did the copy without the propority 'page_version_blockItems'         
          const dynamicKey = "page_version_blockItems"; // removes property based on the dynamic key (page_version_blockItems)
          let {[dynamicKey]: _, ...new_page_version_data} = page_version_data; // Copy the object
          const blocks = page_version_data.page_version_blockItems; // Make a copy for the existing block items
          blocks.map((blockItem, index)=>{
            if(isDefined(blockItem.block_component) && blockItem.block_component !== 'Filter_001'){
              blockIndexes.push(index); // push an index for the filter block
            }
          });
          blockIndexes.map((i) => {
            newBlocks.push(blocks[i]);
          });
          new_page_version_data.page_version_blockItems = newBlocks; // assign the new block that discard the filter box
          return new_page_version_data;
        }
      }
    }
  };