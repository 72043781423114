import {
  NEW_PAGE_FRAGMENT,
  NEW_VERSION_FRAGMENT,
  NEW_PAGE_BLOCK_FRAGMENT,
  NEW_MENU_FRAGMENT,
  NEW_MENU_LINK_FRAGMENT,
} from "../@global/queries";

import logger from "../@global/logger";

export const getCacheTypePolicies = () => {
  return {
    typePolicies: {
      Query: {
        fields: {
          pageItems: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          page_editor_userItems: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Page_versionType: {
        fields: {
          page_version_blockItems: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Page_version_blockType: {
        fields: {
          page_version_block_contentItems: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Page_version_block_contentType: {
        fields: {
          options: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          content: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Site_layoutType: {
        fields: {
          site_layout_contentItems: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Site_layout_contentType: {
        fields: {
          options: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          content: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      MenuType: {
        fields: {
          menu_linkItems: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Mutation: {
        fields: {
          moveBlock: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  };
};

export const onRequestError = (error) => {
  logger.error(error);
};

export const getRenderAdminUI = (token, is_admin, is_editor) => {
  return token === "" ? false : is_admin || is_editor;
};

export const updateCacheOnCreatePage = (cache, { data: { createPage } }) => {
  const modified_pageItems = (existing_pages = []) => {
    const new_page_ref = cache.writeFragment({
      fragment: NEW_PAGE_FRAGMENT,
      data: createPage,
    });
    return [...existing_pages, new_page_ref];
  };

  cache.modify({
    fields: {
      pageItems: modified_pageItems,
    },
  });
};

export const updateCacheOnCreatePageBlock = (version_cache_id) => (
  cache,
  { data: { addBlockToPage } }
) => {
  const modified_blockItems = (existing_blocks = []) => {
    const new_block_refs = addBlockToPage.map((new_block_item) =>
      cache.writeFragment({
        fragment: NEW_PAGE_BLOCK_FRAGMENT,
        data: new_block_item,
      })
    );

    const unmodified_existing_blocks = existing_blocks.filter(
      (existing_block) =>
        !new_block_refs.find((new_block) => new_block.__ref === existing_block.__ref)
    );

    return [...unmodified_existing_blocks, ...new_block_refs];
  };

  cache.modify({
    id: version_cache_id,
    fields: {
      page_version_blockItems: modified_blockItems,
    },
  });
};

export const updateCacheOnCreateMenuLink = (menu_cache_id) => (
  cache,
  { data: { createMenuLink } }
) => {
  const modified_menuLinks = (existing_menu_links = []) => {
    const new_menu_link_ref = cache.writeFragment({
      fragment: NEW_MENU_LINK_FRAGMENT,
      data: createMenuLink,
    });
    return [...existing_menu_links, new_menu_link_ref];
  };

  cache.modify({
    id: menu_cache_id,
    fields: {
      menu_linkItems: modified_menuLinks,
    },
  });
};

export const updateCacheOnCreateMenu = (cache, { data: { createMenu } }) => {
  const modified_menus = (existing_menus = []) => {
    const new_menu_ref = cache.writeFragment({
      fragment: NEW_MENU_FRAGMENT,
      data: createMenu,
    });
    return [...existing_menus, new_menu_ref];
  };

  cache.modify({
    fields: {
      menuItems: modified_menus,
    },
  });
};

export const updateCacheOnCloneVersion = (cache, { data: { cloneVersion } }) => {
  const modified_versions = (existing_version = []) => {
    const new_version_ref = cache.writeFragment({
      fragment: NEW_VERSION_FRAGMENT,
      data: cloneVersion,
    });
    return [...existing_version, new_version_ref];
  };

  cache.modify({
    fields: {
      page_versionItems: modified_versions,
    },
  });
};
