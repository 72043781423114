import {
  TOGGLE_DATA_SAVING_UI,
  SET_SHOW_MENUS_DRAWER,
  SET_REQUESTED_MENU_ID,
  SET_IS_ADMIN,
  SET_IS_EDITOR,
  REQUEST_CALLOUT,
  SET_CALLOUT,
} from "../Types";

const initialState = {
  saving_data: false,
  show_menu_drawer: false,
  requested_menu_id: "",
  is_admin: false,
  is_editor: false,
  callout_anchor: null,
  tour_step: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_DATA_SAVING_UI:
      return {
        ...state,
        saving_data: payload,
      };
    case SET_SHOW_MENUS_DRAWER:
      return {
        ...state,
        show_menu_drawer: payload,
      };
    case SET_REQUESTED_MENU_ID:
      return {
        ...state,
        requested_menu_id: payload,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        is_admin: Boolean(payload),
      };
    case SET_IS_EDITOR:
      return {
        ...state,
        is_editor: Boolean(payload),
      };
    case REQUEST_CALLOUT:
      return {
        ...state,
        tour_step: payload,
      };
    case SET_CALLOUT:
      return {
        ...state,
        callout_anchor: payload,
      };
    default:
      return state;
  }
}
